import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '@app/core/services';
import { Globals } from '@app/app.globals';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClientService {

    api = this.gs.api_url;
    messages: boolean = true;
    constructor(private http: HttpClient, private gs: Globals, private notificationService: NotificationService) { }

    private extractData(res: any) {
        let body = res;
        if (body) {
            return body;
        } else {
            return {}
        }
    }

    private handleError(error: any) {
        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        //        console.error(errMsg); // log to console instead
        return Observable.throw("");
    }

    successful(msg: string, msg_title: string) {
        this.notificationService.smallBox({
            title: msg_title,
            content: msg,
            color: "#739E73",
            iconSmall: "fa fa-thumbs-up bounce animated",
            timeout: 4000
        });
    }
    //
    failure(msg: string, msg_title: string = '') {
        this.notificationService.smallBox({
            title: msg_title,
            content: msg,
            color: "#A90329",
            iconSmall: "fa fa-thumbs-down bounce animated",
            timeout: 4000
        });
    }
    //
    error(err) {
        this.notificationService.bigBox({
            title: "All fields marked with an asterisk (*) are required.",
            content: err,
            color: "#C46A69",
            icon: "fa fa-warning shake animated",
            number: "1",
        });
        return;
    }

  
    deleteClient(CID) {
        return this.http.delete(this.api + 'client/deleteClient/' + CID)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }

    getReport(obj) {
        return this.http.post(this.api + 'getPDFReport', obj)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }

    getClients() {
        return this.http.get(this.api + 'client/getClientList')
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }

    getClientList(sid) {
        return this.http.post(this.api + 'client/getClients', sid)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }




    getCountry() {

        return this.http.get(this.api + 'getCountry')
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )

    }
    getStates(id) {
        return this.http.get(this.api + 'getState/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }

    getCity(id) {
        return this.http.get(this.api + 'getCity/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }



    beforeClientCreate() {
        return this.http.get(this.api + 'client/beforeClientCreate')
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }


    createClient(obj) {
        return this.http.post(this.api + 'client/createClient', obj)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }

    editClient(CID) {
        return this.http.get(this.api + 'client/editClient/' + CID)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }

    updateClient(obj) {
        return this.http.post(this.api + 'client/updateClient', obj)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }

    // -------------------------- Profile Section Starts -------------------------------//
    getClientsDetails(CID) {
      
        return this.http.get(this.api + 'client/getClientProfile/' + CID)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }

    uploadClientBanner(obj) {
        return this.http.post(this.api + 'client/uploadClientBanner', obj)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }
    // -------------------------- Profile Section Ends -------------------------------//

    // -------------------------- Notes Section Starts -------------------------------//
    getClientNotesList(CID) {
        return this.http.get(this.api + 'notes/getClientNotesList/' + CID)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }

    getClientNotesByDates(obj) {
        return this.http.post(this.api + 'notes/getClientNotesByDates', obj)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }

    beforeAddUpdateNote() {
        return this.http.get(this.api + 'notes/beforeAddUpdateNote')
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }

    getSubNoteTypeOptions(note_type) {
        return this.http.get(this.api + 'notes/getSubNoteTypeOptions/' + note_type)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }

    getSubNoteTypeDetails(nst_id) {
        return this.http.get(this.api + 'notes/getSubNoteTypeDetails/' + nst_id)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }

    getNoteData(notes_id) {
        return this.http.get(this.api + 'notes/editClientNote/' + notes_id)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }
    createClientNote(note) {
        note['created_id'] = "1";
        note['created_user'] = "developer";
        return this.http.post(this.api + 'notes/createClientNote', note)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }
    updateClientNote(note) {
        note['created_id'] = "1";
        note['created_user'] = "developer";
        return this.http.put(this.api + 'notes/updateClientNote/' + note.notes_id, note)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }
    deleteClientNote(notes_id) {
        return this.http.delete(this.api + 'notes/deleteClientNote/' + notes_id)
            .pipe(
                map(this.extractData),
                catchError(this.handleError)
            )
    }

    // -------------------------- Notes Section Ends -------------------------------//

}
