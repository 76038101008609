import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '@app/core/services';
import {Globals} from '@app/app.globals';

import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
declare var $: any;
@Injectable({providedIn: 'root'})
export class ManagetablesService {

    REST_ROOT = this.gs.api_url + "manage_tables";
    REST_ROOT1 = this.gs.api_url;
    account = this.gs.current_agency;
    constructor(private http: HttpClient, private gs: Globals, private notificationService: NotificationService) {}

    private extractData(res: any) {
        let body = res;
        if (body) {
            return body
        } else {
            return {}
        }
    }

    private handleError(error: any) {
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        //        console.error(errMsg); // log to console instead
        return Observable.throw(errMsg);
    }

    error(err) {
        this.notificationService.bigBox({
            title: "All fields marked with an asterisk (*) are required.",
            content: err,
            color: "#C46A69",
            icon: "fa fa-warning shake animated",
            number: "1",
        });
        return;
    }

    successful(msg, msg_title) {
        this.notificationService.smallBox({
            title: msg_title,
            content: msg,
            color: "#739E73",
            iconSmall: "fa fa-thumbs-up bounce animated",
            timeout: 4000
        });
    }

    failure(msg, msg_title = "") {
        this.notificationService.smallBox({
            title: msg_title,
            content: msg,
            color: "#A90329",
            iconSmall: "fa fa-thumbs-down bounce animated",
            timeout: 4000
        });
    }

 
    // ---------------------------------Manage Category--------------------------------------
    addcategories(catData: any) {
        return this.http.post(this.REST_ROOT + '/createCategories', catData)
            .pipe(
            map(this.extractData),
            catchError(this.handleError)
            )
    }

    getCategories() {
        return this.http.get(this.REST_ROOT + '/categoriesList')
            .pipe(
            map(this.extractData),
            catchError(this.handleError)
            )
    }

    beforecategories() {
        // return this.http.get(this.REST_ROOT + '/beforecategories')
        //     .pipe(
        //     map(this.extractData),
        //     catchError(this.handleError)
        //     )
    }

    editCategories(cat_id: any) {
        return this.http.get(this.REST_ROOT + '/editCategories/' + cat_id)
            .pipe(
            map(this.extractData),
            catchError(this.handleError)
            )
    }

    updatecategories(CatData: any, cat_id) {
console.log(CatData);
        return this.http.post(this.REST_ROOT + '/updateCategories/' + cat_id, CatData)
            .pipe(
            map(this.extractData),
            catchError(this.handleError)
            )
    }

    deletecategories(cat_id: any) {
        return this.http.delete(this.REST_ROOT + '/deleteCategories/' + cat_id)
            .pipe(
            map(this.extractData),
            catchError(this.handleError)
            )
    }

    // ---------------------------------Manage Products--------------------------------------
    addProducts(productData: any) {
        return this.http.post(this.REST_ROOT + '/createProducts', productData)
            .pipe(
            map(this.extractData),
            catchError(this.handleError)
            )
    }

    getProducts() {
        return this.http.get(this.REST_ROOT + '/productsList')
            .pipe(
            map(this.extractData),
            catchError(this.handleError)
            )
    }

      getCategoryId() {
        return this.http.get(this.REST_ROOT1 + 'getCategories')
            .pipe(
            map(this.extractData),
            catchError(this.handleError)
            )
    }

 

    editProducts(pid: any) {
        return this.http.get(this.REST_ROOT + '/editProducts/' + pid)
            .pipe(
            map(this.extractData),
            catchError(this.handleError)
            )
    }

    updateProducts(productData: any, pid) {
console.log(productData);
        return this.http.post(this.REST_ROOT + '/updateProducts/' + pid, productData)
            .pipe(
            map(this.extractData),
            catchError(this.handleError)
            )
    }

    deleteProducts(pid: any) {
        return this.http.delete(this.REST_ROOT + '/deleteProducts/' + pid)
            .pipe(
            map(this.extractData),
            catchError(this.handleError)
            )
    }



}