import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '@app/app.globals';

import {Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AuthService {
    api = this.gs.api_url;

    constructor(private http: HttpClient, private gs: Globals) {}

    private extractData(res: any) {
        let body = res;
        if (body) {
            if (body.user_details) {
                sessionStorage.setItem('adc_token', body.token);
            }
            return body;
        } else {
            return {}
        }
    }

    private handleError(error: any) {
        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        //        console.error(errMsg); // log to console instead
        return Observable.throw(errMsg);
    }

    login(obj) {
        return this.http.post(this.api + 'login', obj)
            .pipe(
            map(this.extractData),
            catchError(this.handleError)
            )
    }

}
